<template>
  <div id="login" class="hi100 login-phone" >
    <div class="uf uf-ac uf-pc hi100 pr-2 pl-2">
<!--      <div class="" v-if="winWidth>1336" style="margin-right: 10rem;">-->
<!--        <img src="~@/assets/img/login-left.png" alt="">-->
<!--      </div>-->
      <div class="login-box p-5">
        <div class="logo-box uf uf-pc">
          <div class="wrap uf uf-ac uf-pc wi100" style="cursor: pointer" @click="$router.push({name: 'index'})" title="回到首页">
            <img src="~@/assets/img/logo-sp.png" alt="">
          </div>
        </div>
        <div class="tac f18 fwb">欢迎来到服务型制造共性技术公共服务平台</div>
<!--        <el-row style="text-align: center" class="mt-4">-->
<!--          <el-radio-group v-model="loginType" @change="changeType">-->
<!--            <el-radio-button label="normal">账号密码登录</el-radio-button>-->
<!--            <el-radio-button label="sms">短信验证登录</el-radio-button>-->
<!--          </el-radio-group>-->
<!--        </el-row>-->
        <el-form class="mt-4" :model="dataForm" :rules="loginType === 'normal' ? dataRule1 : dataRule2" ref="dataForm" @keyup.enter.native="doSub()"
                 status-icon>
          <el-form-item prop="userName" v-show="loginType ==='normal'">
            <el-input prefix-icon="el-icon-user" v-model="dataForm.userName" placeholder="请输入用户名" clearable></el-input>
          </el-form-item>
          <el-form-item prop="password" v-show="loginType ==='normal'">
            <el-input prefix-icon="el-icon-lock" v-model="dataForm.password" type="password" placeholder="密码"
                      clearable></el-input>
          </el-form-item>
          <el-form-item prop="mobile" v-show="loginType === 'sms'">
            <el-input prefix-icon="el-icon-mobile-phone" v-model="dataForm.mobile" placeholder="手机号"
                      clearable></el-input>
          </el-form-item>
<!--          <el-form-item prop="smsCode" v-show="loginType === 'sms'">-->
<!--            <el-row :gutter="10">-->
<!--              <el-col :span="14">-->
<!--                <el-input prefix-icon="el-icon-key" v-model="dataForm.smsCode" placeholder="短信验证码"></el-input>-->
<!--              </el-col>-->
<!--              <el-col :span="10">-->
<!--                <div class="qrcode">-->
<!--                  <template v-if="smsSuc">-->
<!--                    <el-button type="danger">{{ smsTime }}</el-button>-->
<!--                  </template>-->
<!--                  <template v-else>-->
<!--                    <el-button type="text" @click="getMsgCode">获取短信验证码</el-button>-->
<!--                  </template>-->
<!--                </div>-->
<!--              </el-col>-->
<!--            </el-row>-->
<!--          </el-form-item>-->
          <el-form-item prop="captcha">
            <el-row :gutter="20">
              <el-col :span="14">
                <el-input prefix-icon="el-icon-picture" v-model="dataForm.captcha" placeholder="验证码" clearable></el-input>
              </el-col>
              <el-col :span="10" class="qrcode">
                <img :src="captchaPath" @click="getCaptcha()" alt="">
              </el-col>
            </el-row>
          </el-form-item>
          <el-button type="primary" class="wi100" @click="doSub" :loading="loading">登录</el-button>
          <div class="uf uf-ac uf-pj mt-3">
            <div class="c-base cp f14" @click="$router.push({ name: 'register' })">没有账号？去注册</div>
            <div class="c-base cp f14" @click="$router.replace({ name: 'forgetPassword' })">忘记密码？</div>
          </div>
        </el-form>
      </div>
<!--      <div style="position: absolute;bottom: 20px;color: white">-->
<!--        © 2019-2022 服务型制造共性技术公共服务平台 <a style="text-decoration:none;color: white" href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备20007970号-2</a>-->
<!--      </div>-->
    </div>

  </div>
</template>

<script>
  import {getUUID, encode64, checkIsNull} from '@/utils'
  import {isMobile} from '../../utils/validate'
  export default {
    name: 'login',
    data () {
      return {
        loginType: 'normal',
        dataForm: {
          userName: '',
          password: '',
          uuid: '',
          captcha: '',
          mobile: '',
          smsCode: ''
        },
        captchaPath: '',
        dataRule1: {
          userName: [
            {required: true, message: '帐号不能为空', trigger: 'blur'}
          ],
          password: [
            {required: true, message: '密码不能为空', trigger: 'blur'}
          ],
          captcha: [
            {required: true, message: '验证码不能为空', trigger: 'blur'}
          ]
        },
        dataRule2: {
          captcha: [
            {required: true, message: '验证码不能为空', trigger: 'blur'}
          ],
          mobile: [
            {required: true, validator: isMobile(), trigger: 'blur'}
          ],
          smsCode: [{required: true, message: '短信验证码不能为空', trigger: 'blur'}]
        },
        loading: false,
        smsSuc: false,
        smsTime: ''
      }
    },
    created () {
      this.getCaptcha()
    },
    computed: {
      winWidth :{
        get () { return  document.documentElement.clientWidth }
      },
      loginStatus: {
        get() {
          return this.$store.state.user.loginStatus
        },
        set(val) {
          this.$store.commit('user/updateLoginStatus', val)
        }
      },
    },
    methods: {
      doSub () {
        let _this = this
        this.$refs['dataForm'].validate((valid) => {
          if (valid && !this.loading) {
            let userSource = this.$cookie.get('userSource')
            if (checkIsNull(userSource)) {
              this.$cookie.set('userSource', getUUID())
            }
            this.loading = true
            this.$http({
              url: _this.$http.adornUrl(_this.loginType === 'normal' ? this.$api.SYS.LOGIN : this.$api.SYS.MOBILE_LOGIN),
              method: 'post',
              data: _this.$http.adornData({
                'username': _this.dataForm.userName,
                'mobile': _this.dataForm.mobile,
                'smsCode': encode64(_this.dataForm.smsCode),
                'captcha': _this.dataForm.captcha,
                'uuid': _this.dataForm.uuid,
                'password': encode64(this.$encryptECB(_this.dataForm.password)),
                'accountType': 'SERVICE'
              })
            }).then(({data}) => {
              if (data && data.code === 0) {
                // this.$message.success('登录成功')
                this.$cookie.set('token', data.token)
                this.loginStatus = true
                setTimeout(function () {
                  _this.$router.push({name: 'main'})
                  // _this.$router.push({name: 'serve'})
                }, 1000)
              } else {
                this.getCaptcha()
                this.dataForm.captcha = ''
                this.$message.error(data.msg)
                this.$nextTick(() => {
                  this.loading = false
                })
              }
            })
          }
        })
      },
      changeType () {
        const _this = this
        this.dataForm.uuid = ''
        this.captchaPath = ''
        this.$nextTick(() => {
          this.$refs.dataForm.resetFields()
          _this.$refs.dataForm.clearValidate()
          this.getCaptcha()
        })
      },
      // 获取验证码
      getCaptcha () {
        this.dataForm.uuid = getUUID()
        this.captchaPath = this.$http.adornUrl('/captcha.jpg?uuid=' + this.dataForm.uuid + '&t=' + new Date().getTime())
      },
      getMsgCode () {
        this.$message.error('短信功能未启用！')
        // let _this = this
        // this.$refs['dataForm'].validateField('mobile', (mobileMsg) => {
        //   if (mobileMsg === '') {
        //     _this.$http({
        //       url: _this.$http.adornUrl(_this.$api.SYS.VALID_CODE),
        //       method: 'post',
        //       data: _this.$http.adornData({
        //         'mobile': _this.dataForm.mobile,
        //         'smsTerminal': 'SMS_TERMINAL_LOGIN'
        //       })
        //     }).then(({data}) => {
        //       if (data && data.code === 0) {
        //         this.$message.success('短信发送成功，请注意查收')
        //         _this.smsSuc = true
        //         _this.smsTime = '60s后可重新获取'
        //         _this.countdown(60)
        //       } else {
        //         this.$message.error(data.msg)
        //         _this.getCaptcha()
        //       }
        //     })
        //   }
        // })
      },
      countdown (time) {
        if (time < 1) {
          this.smsSuc = false
          return
        }
        this.smsSuc = true
        this.smsTime = time + 's后可重新获取'
        setTimeout(this.countdown, 1000, --time)
      }
    }
  }
</script>
<style scoped>
  #login {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*text-align: center;*/
    /*background-image: linear-gradient(50deg, #007bff, #51f9fb);*/
    background: url("../../assets/img/login-bg4.jpg");
    background-size: cover;
    height: 100vh;
  }

  body {
    width: 100%;
    height: 100vh;
  }

  .login-box {
    width: 30rem;
    min-height: 20rem;
    /*margin-left: 10rem;*/
    background: #ffffff;
    border-radius: 0.3rem;
    -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
  }
  .login-box .iconfont {
    /*margin-right: -2.4rem;*/
    margin-left: 1.3rem;
    z-index: 999;
    color: rgba(0,0,0,.6);
    font-size: 1.2rem;
  }
  .login-box .form-control {
    padding-left: 2.8rem;
    line-height: 2rem;
  }
  .login-box .btn {
    line-height: 2rem;
  }
  .left-img img{
    width: 32rem;
    height: 32rem;
  }
  .logo-box {
    margin-top: -7rem;
  }
  .logo-box .wrap{
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background: #ffffff;
  }
  .logo-box .wrap img{
    width: 5rem;
    height: auto;
  }
  .qrcode {
    line-height: 100%;
    height: 100%;
    overflow: hidden;
  }
  .iconfont.icon-yanzhengma {
    font-size: 14px;
    margin-left: 6px;
    color: #C0C4CC;
  }
  .qrcode img {
    width:100%;
    height: 100%;
    /*height: 2.5em;*/
    cursor: pointer;
    /*margin-top: 0.2rem;*/
  }
  @media only screen and (max-width: 850px) {
    .login-phone{
      height: 50rem !important;
    }
  }
  .beian{
    height: 2vh;
  }
  /*@media only screen and (max-width: 500px) {*/
  /*  .logo-box {*/
  /*    margin-top: -3rem;*/
  /*  }*/
  /*}*/
</style>
